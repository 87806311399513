		
.quiz-container {
    max-width: 700px;
    min-width: 250px;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 0px;
    padding: 30px 60px;
  }
  
  .quiz-container-student{
    max-width: 700px;
    min-width: 250px;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 100px;
    padding: 30px 60px;
  }

  .quiz-container .active-question-no {
    font-size: 32px;
    font-weight: 500;
  }
  
  
  .quiz-container-student .active-question-no {
    font-size: 32px;
    font-weight: 500;
  }
  
  .quiz-container .total-question {
    font-size: 16px;
    font-weight: 500;
    color: #e0dee3;
  }

  .quiz-container-student .total-question {
    font-size: 16px;
    font-weight: 500;
    color: #e0dee3;
  }
  
  .quiz-container h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }

  .quiz-container-student h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }
  
  .quiz-container ul {
    margin-top: 20px;
  }

  .quiz-container-student ul {
    margin-top: 20px;
  }
  
  .quiz-container ul li {
    text-decoration: none;
    list-style: none;
    color: #2d264b;
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 16px;
    padding: 11px;
    margin-top: 15px;
    cursor: pointer;
  }

  .quiz-container-student ul li {
    text-decoration: none;
    list-style: none;
    color: #2d264b;
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 16px;
    padding: 11px;
    margin-top: 15px;
    cursor: pointer;
  }
  
  .quiz-container ul .selected-answer {
    background: #E5F2FF;
    border: 1px solid #FF7F7F;
  }

  .quiz-container-student ul .selected-answer {
    background: #E5F2FF;
    border: 1px solid #FF7F7F;
  }
  
  .quiz-container button {
    background: #0C4D8D;
    border-radius: 9px;
    font-size: 18px;
    color: #ffffff;
    padding: 10px 42px;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 15px;
  }

  .quiz-container-student button {
    background: #0C4D8D;
    border-radius: 9px;
    font-size: 18px;
    color: #ffffff;
    padding: 10px 42px;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 15px;
  }
  
  .quiz-container button:disabled {
    background: #e7e8e9;
    color: #9fa3a9;
    cursor: not-allowed;
  }

  .quiz-container-student button:disabled {
    background: #e7e8e9;
    color: #9fa3a9;
    cursor: not-allowed;
  }

  .question-container {
    max-width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  
  .question-container img {
    max-width: 100%;
    height: auto;
    display: block; /* Ensures images do not overflow */
  }
  
  
  .flex-right {
    display: flex;
    justify-content: flex-end;
  }
  
  .flex-between {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  
  .result h3 {
    font-size: 24px;
    letter-spacing: 1.4px;
    text-align: center;
  }
  
  .result p {
    font-size: 16px;
    font-weight: 500;
  }
  
  .result p span {
    font-size: 22px;
  }
  
    .quiz-header {
        margin-bottom: 20px;
        text-align: left;
        width: 100%;
        padding: 0;
      }
    
      .quiz-header h1 {
        font-size: 2em;
        margin-bottom: 10px;
        padding: 0;
        margin: 0;
      }
    
       .quiz-header div {
        font-size: 1.2em;
        padding: 0;
        margin: 0;
      }
    .flex-center {
      display: flex;
      align-items: center; 
    }
    
    .timer-icon {
      margin-right: 8px;
      color: #333; 
    }
    
    .timer-text {
      font-size: 1.2rem;
      color: #333; 
    }
    .result {
      background: #f8f9fa; 
      border-radius: 8px;
      padding: 20px;
      text-align: center;
      margin-top: 20px;
    }
    
    .result h3 {
      font-size: 28px; 
      margin-bottom: 20px;
      color: #343a40; 
    }
    
    .result p {
      font-size: 18px; 
      font-weight: 600;
      color: #495057; 
    }
    
    .result p span {
      color: #007bff; 
      font-size: 24px; 
    }
    
  
  /* dark mode */
  
  .dark .quiz-container{
      background:#2B2838
    }
  
    .dark .quiz-container ul li {
      color:#B9B7C0;
      background:#131022;
      }
  
  .quiz-timer {
        font-size: 1.2rem;
        color: #333;
        text-align: right;
      }
      
  
    
      .quiz-info {
        display: flex;
        justify-content: space-between; 
        align-items: center; 
        margin-bottom: 20px; 
        padding: 0;
        margin: 0;
      }
      
      .active-question-no {
        font-size: 1.5rem;
        font-weight: bold;
      }
      
      .total-question {
        font-size: 1.5rem;
        color: #888;
      }
      
      .flex-right {
        font-size: 1.2rem;
        color: #333;
      }
      .dark .timer-icon,
      .dark .timer-text {
      color: #B9B7B7;
      }
      .no-padding-left {
          padding-left: 0 !important;
          margin-left: 0 !important;
          width:100%;
    }
  
    @media (max-width: 767.98px) {
      .quiz-container {
        max-width: 700px;
        min-width: 250px;
        background: #ffffff;
        border-radius: 4px;
        margin-top: 0px !important;
        padding: 30px 60px;
      }

      .quiz-container-student {
        max-width: 700px;
        min-width: 250px;
        background: #ffffff;
        border-radius: 4px;
        margin-top: 20px !important;
        padding: 30px 60px;
      }
    }