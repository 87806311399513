.custom-testimonial {
  padding: 60px 0;
  background: #fcfcfd;
}

.testimonial-header {
  text-align: center;
  margin-bottom: 40px;
}

.testimonial-header h2 {
  font-size: 28px;
  color: #1c1c1c;
  margin-bottom: 10px;
}

.testimonial-header p {
  font-size: 16px;
  color: #383838;
}

.custom-testimonial-content {
  background: #fff;
  padding: 20px;
  margin: 0 15px; /* Adds space between cards */
  box-shadow: 0 4px 6px rgba(232, 231, 235, 0.5); /* Light grey box shadow */
  border-radius: 10px;
  text-align: center; /* Ensures text is also centered */
}


.avatar-img {
  display: block;
  margin: 0 auto; /* Centers the image horizontally */
  border-radius: 50%;
  width: 80px; /* Adjust size as needed */
  height: 80px;
}


.user-name {
  font-family: Helvetica, sans-serif;
  font-weight: 500;
  color: #383838;
  margin-bottom: 5px;
}

.star-rating {
  color: #ffd700;
  margin-bottom: 10px;
}

.testimonial-text {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  color: #383838;
}
