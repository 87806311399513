.scoreboard {
    border: 1px solid #0C4D8D;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    background-color: #f8f8ff;
  }
  
  .scoreboard-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0C4D8D;
    padding: 10px 15px;
  }
  
  .scoreboard-row:last-child {
    border-bottom: none;
  }
  
  .scoreboard-label {
    color: #0C4D8D;
    font-weight: bold;
    flex: 1;
  }
  
  .scoreboard-value {
    color: #0C4D8D;
    text-align: right;
    flex: 1;
  }
  
  .scoreboard-header {
    background-color: #0C4D8D;
    color: white;
    text-align: center;
    padding: 10px 0;
    font-weight: bold;
    border-bottom: 1px solid #0C4D8D;
  }
  
  .scoreboard-label {
    color: #0C4D8D;
    font-weight: bold;
    text-align: left;
    flex: 1;
  }
  
  .scoreboard-value {
    color: #0C4D8D;
    text-align: right;
    flex: 1;
  }
  