/* tool css */

.share-calculator {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background: #F2F2F2;
  box-shadow: 0 4px 10px #0003;
  font-family: 'Helvetica', sans-serif;
  padding: 20px;
  width: -webkit-fill-available;
}
  
.tool-header {
  /* color: #3C474C; */
  font-size: 1.3rem;
  font-weight: 600;
  padding:10px ;
}
  
  .tool-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .tool-column {
    border-bottom: 1px solid #3C474C;
    color: #105074;
    flex: 1 1;
    font-size: 1rem;
    margin-right: 10px;
}
    
  .exchange-rate-result {
    /* backdrop-filter: blur(5px); */
    -webkit-backdrop-filter: blur(5px);
    background: #E2E2E2;
    border-bottom: 1px solid #3C474C;
    border-top: 1px solid #3C474C;
    font-size: 1.1rem;
    font-weight: 600;
    /* height: 40px; */
    font-family: 'Nunito Sans', sans-serif;
}
  

  .toolkit-container {
    border: 1px solid #ccc;
    position: relative;
    display: flex;
    flex-direction: column; /* Change to column to place tabs at the top */
}

.tool-tabs {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
}

.tool-tab {
    display: inline-block;
    padding: 5px;
    margin-right: 10px;
    cursor: pointer;
    background-color: #f1f1f1;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.tool-tab.active {
  color: #fff;
  padding: 0 30px 0 30px;
}

.tool-content {
  border-top: 1px solid #ccc;
  color: #444;
  margin: 0px;
}
.tool-tab, .tool-tabs {
  background-color: #0C4D8C;
}


.exchange-rate-result, .form-input {
  /* font-family: Roboto, sans-serif; */
  font-family: 'Nunito Sans', sans-serif;
  padding: 10px;
}

.tool-header i {
  margin-right: 8px; 
}

.tool-label {
  color: #3C474C;
  font-size: small;
}

.td-tool-value{
  color: #105074;
  flex: 1 1;
  font-size: 1rem;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  
}

.dark .tool-label {
  color: #fff;
}


/* Add basic styling for table */
.tool-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}


.tool-table, 
.tool-table th, 
.tool-table td {
  padding: 8px; 
  
}


.tool-table th {
  text-align: left;
  color: #3C474C;
  font-size: small;
}
.tool-table{
  border:none;
}

.tool-table td, .tool-table tfoot, .tool-table th, .tool-table thead, .tool-table tr {
  border-bottom: 1px solid #3C474C;
}

.mw-100px {
  max-width: 100px !important;
}

.form-input {
  box-sizing: border-box;
  padding: 7px;
  width: 100%;
  background-color: #FFF176;
}

@media (max-width: 768px) {
  
  .share-calculator {
    overflow-x: auto; 
  }

  
  .tool-table th, 
  .tool-table td {
    padding: 6px;
    font-size: 14px; 
  }

}


/* @media screen and (max-width: 481px) {
  .share-calculator {
    overflow-x: auto;  
    -webkit-overflow-scrolling: touch;  
  }

  .tool-table {
    min-width: 600px;  
    table-layout: auto; 
  }
  
  .tool-table th, .tool-table td {
    white-space: nowrap; 
  }
} */

@media screen and (max-width: 481px) {
  .tool-table {
    /* Remove or adjust min-width */
    min-width: auto;  
    width: 100%;
    table-layout: fixed; /* Ensures columns are evenly distributed */
  }
  .tool-table th, .tool-table td {
    white-space: normal; /* Allow wrapping instead of nowrap */
    word-wrap: break-word;
  }
  .tool-table td:empty {
    display: none;
  }
}


.full-width-table {
  width: 100%;
  table-layout: fixed; /* Ensures equal space distribution */
}

.full-width-table td {
  width: 100%; /* This helps to stretch each td evenly */
}

.bg-light-gray {
  background-color: #DDDDDD;
}

.bg-dark {
  background-color: #181818;
  border-radius: 0;
}

.black-background {
  background-color: black;
}

.background-color-white{
  background: #fff;
}
.font-white{
  color:#fff !important;
}
.font-white, 
tr.background-deep-blue.font-white th {
    color: #fff !important;
}
.font-white, 
tr.background-blue.font-white th {
    color: #fff !important;
}
.background-deep-blue {
  background-color: #0C4D8D;
}
.background-blue {
  background-color: #105074;
}

/* Toolkit4.css */
.toolkit4-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
  max-width: 100%; 
  overflow-x: auto; 
}

.toolkit4-content {
  flex: 1 1 50%; 
  min-width: 300px; 
  max-width: 100%;
}

@media (max-width: 1200px) {
  .toolkit4-container {
    flex-direction: column; 
  }

  .toolkit4-content {
    width: 100%; 
  }
}
.toolkit-full-row-header {
  border-bottom: 1px solid #ddd;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 10px;
  text-align: center;
}
.center-image {
  text-align: center; 
}

.center-image img {
  display: block; 
  margin: 0 auto; 
}

.text-center {
  text-align: center !important;
}

.cursor-pointer {
  cursor: pointer !important;
}



.tool-tabs button {
  margin: 5px;
}

.dropdown {
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  position: absolute;
  z-index: 1000;
  top: 0%;
  left: 26%;
}

.dropdown-item {
  display: block;
  padding: 8px 12px;
  text-align: left;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}



